var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout__header" },
    [
      _c("PortTranzit"),
      _vm.isAuth
        ? [
            _c("div", { staticClass: "layout__header-date" }, [
              _vm._v(" " + _vm._s(_vm.currentTime) + " "),
            ]),
            _c(
              "div",
              {
                class: ["layout__header-help", { helpColor: _vm.modal }],
                on: { click: _vm.help },
              },
              [
                _c("HelpIcon", { attrs: { fill: _vm.helpColor } }),
                _c("span", { class: _vm.helpColor }, [_vm._v("Помощь")]),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }