var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM6.95818 5.2379C7.56342 4.56999 8.56884 3.81878 9.99999 3.81879C11.4239 3.8188 12.5389 4.54562 13.1841 5.6041C13.8143 6.63798 13.9861 7.95778 13.6764 9.22583C13.4048 10.3378 12.4984 11.2279 11.8469 11.7652C11.4965 12.0542 11.162 12.2871 10.9158 12.4477C10.792 12.5285 10.6888 12.592 10.6149 12.6363C10.5779 12.6585 10.5482 12.6759 10.5267 12.6883L10.5007 12.7032L10.4926 12.7078L10.4899 12.7094L10.4888 12.71C10.4888 12.71 10.4879 12.7104 9.99999 11.8376L10.4888 12.71C10.0067 12.9795 9.3966 12.8076 9.12711 12.3255C8.85786 11.8439 9.02967 11.2352 9.51079 10.9654L9.5117 10.9649L9.52575 10.9568C9.53853 10.9494 9.55911 10.9374 9.58648 10.921C9.6413 10.8881 9.72288 10.838 9.82316 10.7726C10.025 10.6409 10.2959 10.4519 10.5744 10.2222C11.1809 9.72202 11.6269 9.18762 11.7335 8.75135C11.9312 7.94182 11.8004 7.17673 11.4764 6.6451C11.1673 6.13808 10.6761 5.81879 9.99998 5.81879C9.33112 5.81878 8.82325 6.15817 8.4402 6.58088C8.25045 6.79028 8.10828 7.00327 8.01405 7.16429C7.96751 7.2438 7.93425 7.30792 7.91404 7.34886C7.90398 7.36926 7.89727 7.38366 7.89388 7.39107L7.89152 7.39631C7.67376 7.90096 7.08896 8.13557 6.58242 7.9204C6.0741 7.70447 5.83707 7.11734 6.053 6.60902L6.9734 7C6.053 6.60902 6.053 6.60902 6.053 6.60902L6.05443 6.60567L6.05623 6.60151L6.06092 6.59076L6.07476 6.55994C6.08597 6.5354 6.10122 6.50293 6.12059 6.46369C6.15926 6.38534 6.21479 6.27905 6.28793 6.15408C6.43306 5.90611 6.65379 5.5738 6.95818 5.2379ZM11 15.501C11 16.0529 10.5523 16.5003 9.99999 16.5003C9.44771 16.5003 8.99999 16.0529 8.99999 15.501C8.99999 14.9491 9.44771 14.5016 9.99999 14.5016C10.5523 14.5016 11 14.9491 11 15.501Z",
          fill: _vm.fill,
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0Z",
          fill: _vm.fill,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }