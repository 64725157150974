<template>
  <div class="layout__header">
    <PortTranzit />

    <template v-if="isAuth">
      <div class="layout__header-date">
        {{ currentTime }}
      </div>

      <div :class="['layout__header-help', { helpColor: modal }]" @click="help">
        <HelpIcon :fill="helpColor" />
        <span :class="helpColor">Помощь</span>
      </div>
    </template>
  </div>
</template>

<script>
import { GET_IS_AUTH } from '@/store/actions'
import { mapGetters } from 'vuex'
import { terminalTzDate } from '@/core'
import HelpIcon from '@/assets/icons/HelpIcon.vue'
import PortTranzit from '@/assets/icons/PortTranzit.vue'
export default {
  name: 'TerminalLayoutHeader',
  components: { PortTranzit, HelpIcon },
  data() {
    return {
      currentTime: terminalTzDate(),
      helpColor: '#A7ADB6',
      modal: false,
      timerId: null,
    }
  },
  computed: {
    ...mapGetters({
      isAuth: GET_IS_AUTH,
    }),
  },
  mounted() {
    this.timerId = setInterval(
      () => (this.currentTime = terminalTzDate()),
      20000,
    )
  },
  beforeDestroy() {
    clearInterval(this.timerId)
  },
  methods: {
    help() {
      this.$emit('help-modal')
      this.modal = !this.modal
      this.helpColor = this.modal ? '#4cc0cc' : '#A7ADB6'
    },
  },
}
</script>

<style lang="sass" scoped>
.helpColor
  color: $special !important
.layout__header
  display: flex
  align-items: center
  justify-content: space-between
  padding: 32px 40px

  &-date
    font-family: $fontPrimary
    color: $greyText
    font-weight: 500
    font-size: 24px
  &-help
    font-family: $fontPrimary
    color: $greyText
    font-weight: 500
    font-size: 24px
    display: flex
    align-items: center
    cursor: pointer
    span
      margin-left: 10px
</style>
