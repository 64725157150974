var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "205",
        height: "24",
        viewBox: "0 0 205 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M43.1509 3.09242C45.2588 3.09242 46.9349 3.64826 48.1539 4.75995C49.3729 5.87164 49.9824 7.38757 49.9824 9.30776V9.63623C49.9824 11.5817 49.3729 13.1229 48.1539 14.2346C46.9349 15.3462 45.2588 15.9021 43.1509 15.9021H35.8876V22.648H31.7988V3.09242H43.1509ZM45.081 11.3543C45.5635 10.9753 45.7921 10.3942 45.7921 9.63623V9.30776C45.7921 8.57506 45.5635 8.01923 45.081 7.61497C44.5985 7.23599 43.9128 7.03385 43.0239 7.03385H35.8622V11.9354H43.0239C43.9128 11.9354 44.5985 11.7333 45.081 11.3543Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M54.9842 22.0922C53.7398 21.4859 52.7494 20.6016 52.0891 19.4646C51.4034 18.3277 51.0732 16.9886 51.0732 15.4221V15.0431C51.0732 13.4514 51.4034 12.087 52.0637 10.9248C52.724 9.7626 53.689 8.87831 54.9335 8.27193C56.1779 7.66555 57.6762 7.3371 59.4032 7.3371C61.1047 7.3371 62.5777 7.64029 63.8221 8.27193C65.0665 8.87831 66.0062 9.7626 66.6665 10.9248C67.3267 12.087 67.6569 13.4514 67.6569 15.0431V15.4221C67.6569 16.9633 67.3267 18.3277 66.6665 19.4646C66.0062 20.6016 65.0411 21.4859 63.8221 22.0922C62.5777 22.6986 61.1047 23.0018 59.4032 23.0018C57.727 23.0018 56.2287 22.6986 54.9842 22.0922ZM62.5015 18.1003C63.238 17.4686 63.6189 16.5591 63.6189 15.3968V15.0684C63.6189 13.8556 63.2634 12.9208 62.5269 12.2639C61.7904 11.607 60.7492 11.2785 59.4032 11.2785C58.0318 11.2785 56.9905 11.607 56.254 12.2386C55.5176 12.8955 55.162 13.8304 55.162 15.0431V15.3716C55.162 16.5338 55.543 17.4434 56.2794 18.075C57.0159 18.7066 58.0572 19.0351 59.4032 19.0351C60.7492 19.0604 61.765 18.7319 62.5015 18.1003Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M80.2025 7.71606V11.5312H77.155C75.4534 11.5312 74.2344 11.986 73.5487 12.9208V22.6228H69.6123V7.6908H73.2947L73.3963 9.05513C74.5391 8.14557 75.9613 7.6908 77.6629 7.6908H80.2025V7.71606Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M90.8192 11.5059V17.1654C90.8192 17.696 90.9716 18.1255 91.251 18.4034C91.5303 18.6814 91.9366 18.833 92.47 18.833H95.6953V22.6228H91.962C90.3875 22.6228 89.1685 22.168 88.2542 21.2332C87.34 20.2984 86.8828 19.0604 86.8828 17.4686V11.5059H82.3115V7.69082H85.9686C86.3495 7.69082 86.6035 7.61501 86.7558 7.46342C86.9082 7.31183 86.9844 7.00865 86.9844 6.6044V3.74937H90.8192V7.71608H96.508V11.5312H90.8192V11.5059Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M98.2354 7.10966V3.09242H116.343V7.10966H109.359V22.6228H105.219V7.10966H98.2354Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M128.633 7.71606V11.5312H125.586C123.884 11.5312 122.665 11.986 121.979 12.9208V22.6228H118.043V7.6908H121.725L121.827 9.05513C122.97 8.14557 124.392 7.6908 126.094 7.6908H128.633V7.71606Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M143.565 9.08029C144.937 10.2425 145.623 11.8848 145.623 13.9818V22.6227H141.94L141.813 21.2331C140.492 22.3953 138.613 23.0017 136.201 23.0017C134.22 23.0017 132.67 22.5974 131.604 21.8142C130.512 21.0309 129.979 19.9192 129.979 18.4538C129.979 16.0031 131.553 14.5629 134.677 14.184L141.61 13.3502C141.458 12.6427 141.051 12.1122 140.391 11.7332C139.731 11.3542 138.842 11.1773 137.699 11.1773C136.581 11.1773 135.718 11.3542 135.083 11.7079C134.448 12.0616 134.118 12.5417 134.118 13.1481V13.2491H130.08V13.1481C130.08 11.9858 130.41 10.95 131.045 10.0909C131.68 9.20662 132.569 8.54971 133.737 8.06967C134.905 7.58962 136.251 7.36223 137.801 7.36223C140.264 7.33697 142.169 7.91807 143.565 9.08029ZM139.629 18.9339C140.569 18.5296 141.254 17.9991 141.661 17.2916V16.3568L135.693 17.0642C135.134 17.1148 134.702 17.2664 134.397 17.4937C134.067 17.7211 133.915 17.9991 133.915 18.3275C133.915 19.1107 134.728 19.515 136.378 19.515C137.597 19.5403 138.689 19.3381 139.629 18.9339Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M162.131 9.33309C163.35 10.6722 163.959 12.6429 163.959 15.2705V22.6228H159.998V15.2705C159.998 13.982 159.667 12.9966 159.033 12.3144C158.398 11.6323 157.433 11.2785 156.214 11.2785C155.35 11.2785 154.588 11.4807 153.903 11.9102C153.217 12.3397 152.684 12.9208 152.328 13.6535V22.6228H148.366V7.69082H152.023L152.125 9.13097C153.318 7.96875 154.868 7.38763 156.747 7.3371C159.109 7.3371 160.886 8.01928 162.131 9.33309Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M180.923 11.3796L172.034 18.9593H180.923V22.6228H166.676V18.9593L175.564 11.4049H166.676V7.71609H180.923V11.3796Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M183.743 5.23997C183.286 4.78519 183.058 4.15355 183.058 3.39558C183.058 2.63761 183.286 2.03123 183.743 1.55118C184.2 1.0964 184.785 0.84375 185.521 0.84375C186.258 0.84375 186.867 1.07114 187.299 1.55118C187.756 2.00597 187.959 2.63761 187.959 3.39558C187.959 4.15355 187.73 4.75992 187.273 5.23997C186.816 5.69475 186.232 5.9474 185.496 5.9474C184.785 5.9474 184.2 5.72002 183.743 5.23997ZM187.477 22.6227H183.54V7.69074H187.477V22.6227Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M198.396 11.5059V17.1654C198.396 17.696 198.549 18.1255 198.828 18.4034C199.107 18.6814 199.514 18.833 200.047 18.833H203.272V22.6228H199.539C197.965 22.6228 196.746 22.168 195.831 21.2332C194.917 20.2984 194.46 19.0604 194.46 17.4686V11.5059H189.889V7.69082H193.546C193.927 7.69082 194.181 7.61501 194.333 7.46342C194.485 7.31183 194.562 7.00865 194.562 6.6044V3.74937H198.422V7.71608H204.085V11.5312H198.396V11.5059Z",
          fill: "#212832",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.89365 3.11748V6.47782C5.89365 7.31158 5.25875 7.99375 4.44607 8.06955C4.39528 8.06955 0.916016 8.06955 0.916016 8.06955V22.5973H15.7473V19.2622C15.7473 18.4285 16.3822 17.7463 17.1949 17.6705C17.2457 17.6705 20.725 17.6705 20.725 17.6705V3.14275H5.89365V3.11748ZM13.9188 8.34747C14.7569 8.34747 15.4426 9.02964 15.4426 9.8634V15.8514C15.4426 16.6851 14.7569 17.3673 13.9188 17.3673H7.69677C6.8587 17.3673 6.17301 16.6851 6.17301 15.8514V9.8634C6.17301 9.02964 6.8587 8.34747 7.69677 8.34747H13.9188Z",
          fill: "#4CC0CC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }